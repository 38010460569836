// OpenStreetMap, replaced Google Maps.
jQuery(function(){
	if($('#map').length) {
		var map = $('#map'),
			lat = map.data('lat'),
			lng = map.data('lng');
		if(lat && lng) {
			map = new OpenLayers.Map("map");
			map.addLayer(new OpenLayers.Layer.OSM());
			var lonLat = new OpenLayers.LonLat(lng, lat).transform(new OpenLayers.Projection("EPSG:4326"), map.getProjectionObject());
			var zoom=16;
			var markers = new OpenLayers.Layer.Markers("Markers");
			map.addLayer(markers);
			markers.addMarker(new OpenLayers.Marker(lonLat));
			map.setCenter (lonLat, zoom);
		}
	}
});