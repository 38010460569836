 jQuery(function(){
	var body = $('body'),
		menuTrigger = $('.menu-trigger'),
		subnavTriggers = $('.sub-nav-wrap');

	menuTrigger.on('click', function(e) {
		e.preventDefault();
		body.toggleClass('menu-open');
	});

	$(document).bind('click', function (e) {
		if (!$('.main-nav, .menu-trigger').is(e.target) && $('.main-nav, .menu-trigger').has(e.target).length === 0) {
			body.removeClass('menu-open');
		}
	});
 });